import React from 'react';
import '../styles/FAQ.css';
import MetaTags from '../components/MetaTags';

const FAQ = () => {
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>

      <MetaTags
        title={`FAQs - Placement Master`}
        description={`Find answers to frequently asked questions about Placement Master, including shipping, payments, and digital products.`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.placementmaster.in/faq`}
      />

      <div className="faq-content">
        <div className="faq-text">
          <p>
            At <strong>Placement Master</strong>, we value your questions and aim to provide clear and helpful answers. Below are some common questions and their answers.
          </p>

          <h2>General Questions</h2>

          <h3>What is Placement Master?</h3>
          <p>
            Placement Master is your go-to platform for placement preparation materials, including question papers, mock tests, and interview resources.
          </p>

          <h3>How do I purchase digital products?</h3>
          <p>
            You can browse our collection of digital products, add them to your cart, and complete your purchase using secure payment methods available on our website.
          </p>

          <h2>Shipping & Delivery</h2>

          <h3>Do you offer physical shipping for products?</h3>
          <p>
            Currently, all our products are digital. Once you make a purchase, the product will be delivered to your registered email.
          </p>

          <h3>What if I entered the wrong email address?</h3>
          <p>
            Please contact our support team immediately at <strong>support@placementmaster.in</strong> with the correct email address. We’ll assist you in updating your details and accessing your product.
          </p>

          <h2>Payments & Refunds</h2>

          <h3>What payment methods do you accept?</h3>
          <p>
            We accept major credit cards, debit cards, UPI, and PayPal for international transactions.
          </p>

          <h3>Do you offer refunds?</h3>
          <p>
            Since our products are digital and instantly delivered, we generally do not offer refunds. However, if you face any issues, please reach out to our support team for assistance.
          </p>

          <h2>Support</h2>

          <h3>How can I contact support?</h3>
          <p>
            Our support team is available 7 days a week. Reach out to us via email at <strong>support@placementmaster.in</strong> or use the contact form on our website.
          </p>

          <p>Merchant Legal entity name: <strong>PLACEMENT MASTER TECH (OPC) PRIVATE LIMITED</strong></p>
        </div>

        <div className="faq-images">
          <img
            src={'https://cdn.shopify.com/s/files/1/0590/7873/8097/files/signing-shipping-forms-for-large-box_720x.jpg?v=1628863960'}
            alt="FAQs"
            className="faq-image"
          />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
